export enum PurchaseFlowStepEnum {
  OutboundSearchResults = 'outboundSearchResults',
  OutBoundJourneyDetails = 'outBoundJourneyDetails',
  ReturnSearchResults = 'returnSearchResults',
  ReturnJourneyDetails = 'returnJourneyDetails',
  VehicleDetails = 'vehicleDetails',
  Summary = 'summary',
  Payment = 'payment',
  PaymentConfirmation = 'paymentConfirmation',
  PaymentQueued = 'paymentQueued',
  BusConnections = 'busConnections',
}

// The field names that define the multi-ticket purchase flow steps in ContentfulMultiTicketPurchaseFlow type
export enum MultiTicketPurchaseFlowStepEnum {
  StartingPage = 'startingPage',
  OffersPage = 'offersPage',
  SummaryPage = 'multiTicketSummaryPage',
  PaymentPage = 'multiTicketPaymentPage',
  PaymentConfirmationPage = 'paymentConfirmationPage',
  PaymentQueuedPage = 'paymentQueuedPage',
}

export enum ProfilePurchaseFlowStepEnum {
  PreOrderBreakfastPage = 'preOrderBreakfastPage',
  PaymentPage = 'paymentPage',
  SummaryPage = 'summaryPage',
}

export enum GroupSalesPurchaseFlowStepEnum {
  GroupSalesPaymentPage = 'groupSalesPaymentPage',
}

export enum JourneyChangeFlowStepsStepEnum {
  JourneySearchPage = 'journeySearchPage',
  JourneyDetailsPage = 'journeyDetailsPage',
  PaymentPage = 'paymentPage',
  SummaryPage = 'summaryPage',
}
