import { usePathname } from 'next/navigation';
import { useMemo, useRef } from 'react';

import { StepKey } from 'components/structural/PurchaseFlowSteps/types';

import { useSiteData } from 'contexts/siteData';

import { entries } from 'utils/object';

export const useCurrentPurchaseFlowStep = (): StepKey | null => {
  const { purchaseFlowSteps } = useSiteData();
  const pathname = usePathname();
  const stepRef = useRef<StepKey | null>(null);

  const paths = useMemo(
    () => [
      ...entries(purchaseFlowSteps.singleTicketPurchaseFlowSteps),
      ...entries(purchaseFlowSteps.journeyChangeFlowStepsSteps),
      ...entries(purchaseFlowSteps.multiTicketPurchaseFlowSteps),
      ...entries(purchaseFlowSteps.profilePurchaseFlowSteps),
      ...entries(purchaseFlowSteps.groupSalesPurchaseFlowSteps),
    ],
    [purchaseFlowSteps],
  );

  stepRef.current = paths.find(([, { path }]) => path === pathname)?.[0] ?? null;

  return stepRef.current;
};
