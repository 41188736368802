import { useMemo } from 'react';

import { StepKey } from 'components/structural/PurchaseFlowSteps/types';

import {
  GroupSalesPurchaseFlowStepEnum,
  JourneyChangeFlowStepsStepEnum,
  MultiTicketPurchaseFlowStepEnum,
  ProfilePurchaseFlowStepEnum,
  PurchaseFlowStepEnum,
} from 'types/purchaseFlow';
import { PurchaseFlowType } from 'types/sales';

import { useLocation } from 'utils/navigation';
import { entries } from 'utils/object';
import qs from 'utils/qs';

import { useCurrentPurchaseFlowStep } from './useCurrentPurchaseFlowStep';

const flowMap: Array<[PurchaseFlowType, StepKey[]]> = entries({
  [PurchaseFlowType.SingleTicket]: Object.values(PurchaseFlowStepEnum),
  [PurchaseFlowType.MultiTicket]: Object.values(MultiTicketPurchaseFlowStepEnum),
  [PurchaseFlowType.Meals]: Object.values(ProfilePurchaseFlowStepEnum),
  [PurchaseFlowType.ChangeDeparture]: Object.values(JourneyChangeFlowStepsStepEnum),
  [PurchaseFlowType.GroupSales]: Object.values(GroupSalesPurchaseFlowStepEnum),
});

export const useCurrentPurchaseFlow = () => {
  const step = useCurrentPurchaseFlowStep();
  const { search } = useLocation();

  return useMemo(() => {
    const query = qs.parse(search);
    if (
      query.flow === PurchaseFlowType.ChangeDeparture ||
      query.flow === PurchaseFlowType.Meals ||
      query.flow === PurchaseFlowType.GroupSales ||
      query.flow === PurchaseFlowType.GroupSalesMeals ||
      query.flow === PurchaseFlowType.ChangeSeat ||
      query.flow === PurchaseFlowType.AddVehicles ||
      query.flow === PurchaseFlowType.AddBicycles ||
      query.flow === PurchaseFlowType.AddExternalBusLeg
    ) {
      return query.flow;
    }

    if (!step) return undefined;

    const [flow] = flowMap.find(([, steps]) => steps.includes(step)) ?? [];

    return flow ?? undefined;
  }, [search, step]);
};
