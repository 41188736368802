'use client';

import * as Sentry from '@sentry/nextjs';

import { GraphQLFormattedError } from 'graphql';
import dynamic from 'next/dynamic';
import { RequireOneOrNone } from 'type-fest';

import { FailedToAddLongTermCodeReason } from 'backend/types.codegen';

import { log } from 'logging/log';

import { LocalizationKey } from 'constants/i18n.messages';

import { useCurrentPurchaseFlow } from 'hooks/purchaseFlow/useCurrentPurchaseFlow';

import { isEnum } from 'types/typeGuards';

import { pushJourneyOptionExpiredEvent } from 'analytics/purchaseFunnel/pushJourneyOptionExpiredEvent';
import { pushSalesSessionExpiredEvent } from 'analytics/purchaseFunnel/pushSalesSessionExpiredEvent';
import { useEffect } from 'react';
import styles from './ErrorBoundary.module.css';

const journeyOptionExpired = new RegExp(/^(?:Offers with id -)(.*)(?: could not be fetched)$/);

const isJourneyOptionExpired = (error: Error): boolean =>
  !!error.message?.match(journeyOptionExpired);

export class SalesSessionError extends Error {
  readonly expired: boolean;

  constructor(message?: string, extra?: { expired?: boolean }) {
    super(message);
    this.expired = extra?.expired ?? false;
  }
}
export class CiamGeneralError extends Error {}
export class LongTermCodeApplyError extends Error {
  reason: FailedToAddLongTermCodeReason | undefined = undefined;

  constructor(error: GraphQLFormattedError) {
    super(error.message);
    this.reason = isEnum(FailedToAddLongTermCodeReason)(error.extensions?.reason)
      ? error.extensions.reason
      : undefined;
  }
}

type CustomErrorProps = {
  titleKey: LocalizationKey;
  bodyKey: LocalizationKey;
  buttonKey?: LocalizationKey;
} & RequireOneOrNone<{
  buttonHref: string;
  buttonAction: 'reload';
}>;

export class CustomError extends Error {
  titleKey: LocalizationKey;
  bodyKey: LocalizationKey;
  buttonKey?: LocalizationKey;
  buttonHref?: string;
  buttonAction?: 'reload';

  constructor(
    message: string,
    { titleKey, bodyKey, buttonKey, buttonHref, buttonAction }: CustomErrorProps,
  ) {
    super(message);
    this.titleKey = titleKey;
    this.bodyKey = bodyKey;
    this.buttonKey = buttonKey;
    this.buttonHref = buttonHref;
    this.buttonAction = buttonAction;
  }
}

type SupportedError = SalesSessionError | CiamGeneralError | Error;

type ErrorComponentProps = {
  error: null | SupportedError;
  reset: () => void;
};

const SalesSessionErrorRenderer = dynamic(() => import('./Errors/SalesSessionErrorRenderer'));
const DefaultErrorRenderer = dynamic(() => import('./Errors/DefaultErrorRenderer'));
const CustomErrorRenderer = dynamic(() => import('./Errors/CustomErrorRenderer'));
const CiamGeneralErrorRenderer = dynamic(() => import('./Errors/CiamGeneralErrorRenderer'));
const LongTermCodeErrorRenderer = dynamic(() => import('./Errors/LongTermCodeErrorRenderer'));

const ErrorComponent = ({ error, reset }: ErrorComponentProps) => {
  const flow = useCurrentPurchaseFlow();

  if (error instanceof LongTermCodeApplyError && flow) {
    return <LongTermCodeErrorRenderer flow={flow} error={error} />;
  }

  if (error instanceof SalesSessionError && flow) {
    return <SalesSessionErrorRenderer flow={flow} expired={error.expired} />;
  }

  if (error instanceof CustomError) {
    return <CustomErrorRenderer error={error} reset={reset} />;
  }

  if (error instanceof CiamGeneralError) {
    return <CiamGeneralErrorRenderer />;
  }

  return <DefaultErrorRenderer reset={reset} />;
};

const HANDLED_ERRORS = [SalesSessionError, CustomError];

export const ErrorBoundary = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const isHandledError = HANDLED_ERRORS.some((errorType) => error instanceof errorType);

  if (!isHandledError) {
    log(error, {
      errorContextTag: 'React ErrorBoundary',
    });
  }

  if (error instanceof SalesSessionError && error.expired) {
    pushSalesSessionExpiredEvent();
  }

  if (isJourneyOptionExpired(error)) {
    pushJourneyOptionExpiredEvent();
  }

  return (
    <div className={styles.message}>
      <ErrorComponent error={error} reset={reset} />
    </div>
  );
};
