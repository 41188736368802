import { YesNo } from './types';

type GtmEvent = {
  event?: string;
  eventAction?: string;
  eventLabel?: string | null;
  eventCategory?: string;
  eventNonInteraction?: boolean;
  pagePath?: string;
  timestamp?: number;
  order_number?: string;
  action?: string;
  excludeReferrer?: boolean;
  [key: string]: any;
  loggedin?: YesNo;
  loginStatus?: YesNo;
};

const pushToDataLayer = <T extends GtmEvent>(event: T) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push(event);
};

export default pushToDataLayer;
