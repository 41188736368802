'use client';
import { ApolloError } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { GraphQLError } from 'graphql';
import { ValidationError } from 'yup';

import { isProduction } from 'utils/envs';

import { Options } from './log.types';

/** This type is an attempt to cover known types so maybe we can get rid of "unknown" below some day */
type LoggedError = Error | ApolloError | ErrorResponse | GraphQLError | ValidationError;

/**
 * Logs data to Sentry.
 * @param error Error object.
 * @param options Extra information to be passed in the error.
 */
export const log = (error: unknown | LoggedError, options: Options = {}) => {
  const { errorContextTag, reactComponentStack, severity = 'error' } = options;

  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.log('log called', { error });
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setLevel(severity);
    if (errorContextTag) scope.setTag('error_context', errorContextTag);
    if (reactComponentStack) scope.setExtra('React.componentStack', reactComponentStack);

    // `apollo-link-error` has typed the object as GraphQLError, but it is something else!
    const gqlError = error as GraphQLError;
    // use duck typing to assume any Error with locations to be a "GraphQLError"
    if (gqlError.locations) {
      if (gqlError.originalError) {
        scope.setExtra(`GraphQL`, gqlError);
        error = gqlError.originalError;
      } else {
        // @ts-ignore request actually exists in these "GraphQLErrors"
        error.message = `Operation "${gqlError.request?.operationName || 'unknown'}" in path "${
          gqlError.path?.join('/') || 'unknown'
        }" failed`;
      }
    }

    Sentry.captureException(error);
  });
};
