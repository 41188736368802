import { Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { NextWebVitalsMetric } from 'next/app';
import { WEB_VITALS } from 'next/dist/shared/lib/utils';
import { ReactNode, isValidElement } from 'react';

import { WebVitals } from 'analytics/types';

import { FilterByTypename } from 'backend/types';
import {
  Calendar,
  CreateSeriesBundleOffersResponse,
  FailedToFetchResponse,
  JourneyOptionFragment,
  SeriesBundleOffers,
  UserDetailsFragment,
  UserIdentityFragment,
} from 'backend/types.codegen';

import { LOCALE } from 'constants/i18n';

import { PassengerGroup } from 'hooks/useSetSearchParameters/useSetSearchParameters.types';

import { hasOwnProperty, isDefinedObject, isObject } from './utils';

export const isSeriesBundleOffers = (
  props?: CreateSeriesBundleOffersResponse,
): props is SeriesBundleOffers => {
  return props?.__typename === 'SeriesBundleOffers';
};

export const isConsumerUserDetails = (
  value?: UserDetailsFragment,
): value is FilterByTypename<UserDetailsFragment, 'ConsumerUserDetails'> =>
  value?.__typename === 'ConsumerUserDetails';

export const isInfoNotice = (value?: Entry<any>): value is ContentfulWeb.TypeInfoNotice =>
  value?.sys.contentType.sys.id === 'infoNotice';
export const isCalendar = (value?: any): value is Calendar => value?.__typename === 'Calendar';

export const isEnum =
  <T extends object>(e: T) =>
  (value: any): value is T[keyof T] =>
    Object.values(e).includes(value);

export const mapEnum = <T extends Record<string, unknown>>(e: T) => {
  const typeMatches = isEnum(e);
  return (value: any): T[keyof T] | undefined => (typeMatches(value) ? value : undefined);
};

export const isArray = (value: unknown): value is readonly any[] => Array.isArray(value);

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === 'string');

export const isObjectArray = <T>(value: T): value is Extract<T, Array<Record<any, any>>> =>
  Array.isArray(value) && value.every(isDefinedObject);

export const isEnumArray = <T extends Record<string, string>>(
  value: unknown,
  enumObject: T,
): value is T[keyof T][] => {
  const enumValues = Object.values(enumObject);
  return isStringArray(value) && value.every((item) => enumValues.includes(item));
};

export const isLocale = (value: string): value is LOCALE =>
  Object.values<string>(LOCALE).includes(value);

export const isFailedToFetchResponse = (obj: unknown): obj is FailedToFetchResponse =>
  'message' in (obj as FailedToFetchResponse);

export const isContentfulEntry = (obj: unknown): obj is Entry<any> =>
  isDefinedObject(obj) && (obj as any)?.sys?.type === 'Entry';

export const isContentfulDocument = (obj: unknown): obj is Document =>
  isDefinedObject(obj) && (obj as any)?.nodeType === 'document';

export const isPassengerGroup = (
  maybePassengerGroup: unknown,
): maybePassengerGroup is PassengerGroup => {
  return (
    isObject(maybePassengerGroup) &&
    hasOwnProperty(maybePassengerGroup, 'type') &&
    hasOwnProperty(maybePassengerGroup, 'amount')
  );
};

export const isJourneyOptionFragment = (obj: unknown): obj is JourneyOptionFragment => {
  return isObject(obj) && hasOwnProperty(obj, '__typename') && obj.__typename === 'JourneyOption';
};

export const isReactNode = (value: any): value is ReactNode => {
  return (
    isValidElement(value) ||
    (typeof value === 'function' && value.prototype.isReactComponent) ||
    (typeof value === 'function' && value.prototype && value.prototype.render) ||
    (typeof value === 'object' && value.$$typeof === Symbol.for('react.fragment'))
  );
};

export const isNextWebVitalsMetric = (
  props?: unknown,
): props is NextWebVitalsMetric & { label: 'web-vital'; name: (typeof WEB_VITALS)[number] } => {
  return (props as any)?.label === 'web-vital' && WEB_VITALS.includes((props as any)?.name);
};

export const isWebVitalsEvent = (props?: unknown): props is WebVitals => {
  return (props as any)?.event === 'coreWebVitals';
};

export const isUserIdentityFragment = (props?: unknown): props is UserIdentityFragment => {
  return isObject(props) && hasOwnProperty(props, 'isAuthenticated');
};

export const hasDefinedProperty = <T, Prop extends keyof T>(
  value: T,
  property: Prop,
): value is T & { [key in Prop]: NonNullable<T[Prop]> } => value[property] != null;
