import { ReactNode, createContext, useContext } from 'react';

const notProvided = Symbol('notProvided');

export const createProvider = <T,>(name: string) => {
  const context = createContext<T | typeof notProvided>(notProvided);

  const useProvidedValue = () => {
    const value = useContext(context);
    if (value === notProvided) {
      throw new Error(`Provider missing: ${name}`);
    }
    return value;
  };

  const Provider = ({ value, children }: { value: T; children: ReactNode }) => (
    <context.Provider value={value}>{children}</context.Provider>
  );

  Provider.displayName = name;

  return [useProvidedValue, Provider] as const;
};
