export enum PurchaseFlowType {
  SingleTicket = 'singleTicket',
  MultiTicket = 'multiTicket',
  Meals = 'meals',
  ChangeDeparture = 'changeDeparture',
  GroupSales = 'groupSales',
  GroupSalesMeals = 'groupSalesMeals',
  ChangeSeat = 'changeSeat',
  AddVehicles = 'addVehicles',
  AddBicycles = 'AddBicycles',
  AddExternalBusLeg = 'AddExternalBusLeg',
}

export enum NetBankResponseStatus {
  SUCCESS = 'ok',
  CANCEL = 'c',
  FAILED = 'f',
}

export enum PaymentResponseStatus {
  SUCCESS = 'OK',
  CANCEL = 'Cancel',
  REFUSED_CAN_RETRY = 'RefusedCanRetry',
  REFUSED_CANNOT_RETRY = 'RefusedCannotRetry',
  ERROR = 'Error',
}

/**
 * from: https://developer.nexigroup.com/netaxept/en-EU/docs/response-codes/
 */
export const NETS_SYSTEM_ERRORS = [
  '91', // Refused by Issuer because Issuer is temporarily inoperative. You can try to submit the transaction again.
  '92', // Refused by Issuer because Issuer is temporarily inoperative. You can try to submit the transaction again.
  '95', // Refused by Issuer because Issuer is temporarily inoperative. You can try to submit the transaction again.
  '96', // Refused by Issuer because of system malfunction. You can try to submit the transaction again.
];

/**
 * from: https://developer.nexigroup.com/netaxept/en-EU/docs/response-codes/
 */
export const NETS_RETRYABLE_ERRORS = [
  '19', // Refused by Issuer. You can try to submit the transaction again.
  '68', // Refused by issuer because response received to late. You can try to submit the transaction again.
  '99', // Several errors can result in this error code. Refer to the Response text for details
  'N7', // Refused by Issuer because of invalid CVV/CVC security code.
  '1A', // Issuer has soft declined the transaction. 3D Secure (or equivalent) authentication is required.
];

/**
 * from: https://developer.nexigroup.com/netaxept/en-EU/docs/response-codes/
 */
export const NETS_NOT_RETRYABLE_ERRORS = [
  '01', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '02', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '04', // Refused by Issuer.
  '05', // Refused by Issuer.
  '06', // Refused by Issuer.
  '07', // Refused by Issuer.
  '08', // Refused by Issuer.
  '09', // Refused by Issuer.
  '10', // Refused by Issuer.
  '21', // Refused by Issuer.
  '25', // Refused by Issuer.
  '33', // Expired card.
  '34', // Refused by Issuer.
  '35', // Refused by Issuer.
  '36', // Refused by Issuer.
  '37', // Refused by Issuer.
  '41', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '43', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '51', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '52', // Refused by Issuer because of no checking account.
  '54', // Refused by Issuer because of the expired card.
  '56', // Refused by Issuer because of no card record.
  '57', // Refused by Issuer of transaction not permitted to consumer.
  '58', // Refused by Issuer of transaction not permitted.
  '59', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '60', // Refused by Issuer.
  '61', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '62', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '63', // Refused by Issuer.
  '65', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '66', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  '78', // Refused by Issuer.
  '79', // Refused by Issuer.
  '80', // Refused by Issuer.
  '93', // Refused by Issuer. You can advise the consumer to contact their Issuer for more information.
  'B2', // Card is blocked. Please advise your customer to contact their Issuer for more information.
  'B3', // Fraud. Please advise your customer to contact their Issuer for more information.
  'C9', // Refused by Issuer.
  'N0', // Refused by Issuer.
  'P1', // Refused by Issuer.
  'P9', // Refused by Issuer.
  'T3', // Refused by Issuer.
  'T8', // Refused by Issuer.
];
